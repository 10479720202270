.splash-page {
  width: 80%;
  margin: auto;
  padding-top: 3rem;
  display: flex;
  justify-content: center;
}

.row {
  display: flex;
  align-items: center;
  width: 100%;
}

.col-full {
  display: flex;
  width: 100%;
  justify-content: center;
}

.arrow-wrap {
  color: #394855;
  display: flex;
  align-items: center;
}

.arrow {
  margin: 1.5rem;
  font-size: 2rem;
  cursor: pointer;
}

.arrow:active {
  transform: translateY(4px);
}

.img-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  height: 10rem;
  width: 20rem;
}

img {
  /* max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto; */

  /* width: 100%; */
  object-fit: cover;

  /* width: 100%; */
  box-shadow: 6px 6px 4px gray;
}

.heart-wrap {
  position: absolute;
  right: 0;
  top: 0;
}

.heart {
  font-size: 1.5rem;
}

.splash-image {
  max-width: 13rem;
}

.splash-heart {
  top: 1rem;
  right: 1rem;
}

.splash-heart-wrap {
  position: absolute;
  top: 3rem;
  right: 3rem;
}

@media (767px <= width) {
  .img-wrap {
    height: 30rem;
    width: 50rem;
  }

  .splash-image {
    max-width: 45rem;
  }
}

/* @media (width: >= 1200px) {
  .img-wrap {
      height: 20rem;
      width: 42rem;
    }

    .splash-image {
      max-width: 45rem;
    }
} */
