#gallery-text {
  width: 15rem;
  height: 7rem;
  padding: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #9fb4c7;
  font-size: 1rem;
}

.sub-error {
  color: red;
  text-align: center;
}

#gallery-text:active {
  border: 1px solid #394855;
}

.textarea-wrap {
  padding-bottom: 2rem;
  width: 40%;
}

.gallery-submission {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.gallery-sub-title {
  padding-bottom: 1rem;
  font-size: 1.5rem;
  color: #394855;
}

.gallery-button {
  font-size: 1.5rem;
  color: #f9f9f9;
  background-color: #9fb4c7;
  border-color: #9fb4c7;
  border-radius: 0.25rem;
  width: 40%;
}

.gallery-sub-button-wrap {
  display: flex;
  justify-content: center;
}

.return-links {
  display: flex;
  text-decoration: underline;
  column-gap: 2rem;
  color: #9fb4c7;
  font-size: 1rem;
}

.return-text-wrap {
  display: flex;
  justify-content: center;
}

.standard-error {
  text-align: center;
  padding-top: 2rem;
  font-family: BebasNeue, sans-serif;
  font-size: 2rem;
}

.delete-gallery-text {
  color: red;
  text-decoration: underline;
  font-size: 0.75rem;
  padding-left: 1rem;
}

@media (1200px <= width) {
  #gallery-text {
    width: 25rem;
  }
}
