.profile-row {
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.profile-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 3rem;
}

.profile-title {
  color: #8f7682;
  font-size: 2.5rem;
}

.curators-title {
  color: #394855;
  font-size: 2rem;
}

.profile-button {
  width: 18rem;
  height: 5rem;
  background-color: #9fb4c7;
  color: #f9f9f9;
  font-size: 1.5rem;
  border-color: #9fb4c7;
  border-radius: 1rem;
}

.profile-button:active {
  transform: translateY(4px);
}

.profile-button-wrap {
  padding: 1rem 0;
}

.right {
  height: 100%;
}

.search-bar-wrap {
  width: 80%;
}

.search-bar {
  border-radius: 1.5rem;
  width: 100%;
  height: 2rem;
  text-indent: 1rem;
  font-size: 1rem;
  color: #394855;
  border-color: #f9f9f9;
}

.search-error {
  font-size: 0.85rem;
  color: red;
  padding-top: 1rem;
}

.search-results {
  width: 100%;
}

.user-result-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  margin: auto;
}

.user-name-wrap {
  display: flex;
  color: #394855;
}

.user-name {
  letter-spacing: 0.5px;
}

.follow-button-wrap {
  display: flex;
}

.follow-button {
  padding: 0.3rem 1rem;
  border-radius: 0.25rem;
}

.follower-list-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}

.follower-list {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.followers-title {
  color: #394855;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
}

.followed {
  background-color: greenyellow;
  color: #f9f9f9;
}

.user-column {
  width: 50%;
  display: flex;
  align-items: center;
}

.profile-right {
  justify-content: right;
}

.followed-user-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
}

.follower-link {
  display: flex;
  justify-content: left;
  color: #394855;
  text-decoration: none;
}

.unfollow {
  display: flex;
  justify-content: right;
  text-decoration: underline;
  color: rgb(147, 0, 0);
  font-size: 0.75rem;
}

@media (1200px <= width) {
  .profile-row {
    flex-direction: row;
  }

  .profile-column {
    width: 50%;
  }

  .right {
    border-left: 3px solid #394855;
  }

  .search-bar-wrap {
    width: 50%;
  }
}
