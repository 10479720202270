@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.modal-wrap {
  display: flex;
  justify-content: center;
}

.loading-wrap {
  color: #8f7682;
  display: flex;
  justify-content: center;
}

.spinner-row {
  display: flex;
  justify-content: center;
}

.spinner {
  width: 2rem;
  height: 2rem;
  border: 5px solid lightgrey;
  border-top: 5px solid #394855;
  border-radius: 50%;
  animation: spinner 0.75s ease-in-out infinite;
}
