.favorites-col {
  display: flex;
  flex-direction: column;
}

.gallery-addition {
  text-decoration: underline;
  margin: 0;
  font-size: 0.75rem;
  color: #9ba3a9;
}

.no-favorites {
  color: #394855;
  font-size: 1.5rem;
}
