.gray-out {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: rgba(177, 177, 177, 60%);
}

.gray-out:hover {
  cursor: pointer;
}
