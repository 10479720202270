.department-wrap {
  max-width: 70%;
  margin: auto;
  background-color: #f9f9f9;
  padding-bottom: 2rem;
}

.department-row {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.department-col-half {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title-wrap > h1 {
  padding-top: 2rem;
  color: #8f7682;
  padding-left: 2rem;
  font-size: 1rem;
}

.department-title {
  font-size: 1rem;
  color: #394855;
}

.department-title:hover {
  text-decoration: underline;
}

.department-link {
  text-decoration: none;
}

@media (767px <= width) {
  .department-title {
    font-size: 1.5rem;
  }

  .title-wrap > h1 {
    font-size: 2rem;
  }

  .department-row {
    flex-direction: row;
  }
}
