.nav-bar-wrap {
  font-family: BebasNeue, sans-serif;
  background-color: #394855;
  padding: 1rem 0;
  color: #f9f9f9;
  position: relative;
}

.nav-bar {
  max-width: 60%;
  margin: auto;
}

::placeholder {
  color: #d0d0d0;
}

.row {
  display: flex;
  align-items: center;
}

.col-half {
  display: flex;
  align-items: center;
  width: 50%;
}

.right {
  justify-content: end;
}

.palette {
  font-size: 20px;
  padding-right: 0.25rem;
  margin-bottom: 0.2rem;
}

.title-text {
  margin: 0;
  font-size: 1.5rem;
  padding-left: 0;
  color: #f9f9f9;
  text-decoration: none;
}

.small-text {
  font-size: 1rem;
}

.small-text:active {
  transform: translateY(4px);
}

.bars {
  position: absolute;
  top: 1.5rem;
  left: 1rem;
  font-size: 1.25rem;
}

.bars:active {
  transform: translateY(4px);
}

.drawer {
  position: absolute;
  z-index: 2;
  background-color: #f9f9f9;
  width: 15rem;
  box-shadow: 0 6px 4px gray;
  height: 100%;
  transition: transform 0.2s ease-in-out;
}

.drawer-content {
  width: 80%;
  margin: auto;
}

.drawer-links {
  display: flex;
  flex-direction: column;
}

.link {
  text-decoration: none;
  color: #394855;
  margin: 0.25rem 0;
}

.link:hover {
  font-weight: bold;
}

.link:active {
  transform: translateY(4px);
}

.close:hover {
  font-weight: bold;
}

.hidden {
  transform: translateX(-283px);
}

.shown {
  transform: translateX(0);
}

.hover-pointer:hover {
  cursor: pointer;
}

@media (767px <= width) {
  .title-text {
    font-size: 2.5rem;
  }

  .small-text {
    font-size: 2rem;
  }

  .bars {
    font-size: 2rem;
  }

  .palette {
    font-size: 30px;
  }
}
