.display-column {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  /* margin: auto; */
}

.inner-display-column {
  height: 12rem;

  /* width: 20rem; */
  display: flex;
  justify-content: center;
}

.piece-wrap {
  width: 100%;

  /* box-shadow: 6px 6px 4px darkgrey; */
  border-left: 4px solid #ebebeb;
  border-right: 4px solid #ebebeb;
  padding: 1rem 2.5rem;
}

.art-object-wrap {
  padding: 1.5rem 0;
  width: 100%;
}

.image-link {
  height: 12rem;
  width: 15rem;
  display: flex;
  justify-content: center;
}

.multi-link {
  text-decoration: none;
}

.multi-link:hover {
  font-weight: normal;
}

.art-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.information-row {
  display: flex;

  /* width: 100%; */
  width: 15rem;
}

.information-column {
  border-left: 2px solid #394855;

  /* margin-left: 2rem; */

  margin-top: 2rem;
  padding-left: 2rem;
  position: relative;
  height: 8rem;

  /* margin-right: auto; */

  max-width: 55%;

  /* width: 100%; */
}

.title-column {
  display: flex;
  width: 80%;
}

.art-wrap {
  justify-content: center;
  display: flex;
}

.art {
  width: 100%;
}

.art-title {
  color: #394855;
  font-size: 0.75rem;
}

.artist-title {
  color: #9ba3a9;
  font-size: 0.75rem;
  width: 80%;
  display: flex;
}

.display-title {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
  color: #8f7682;
}

.art-date {
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid #9ba3a9;
}

.belleza-font {
  font-family: Belleza, serif;
}

.heart-column {
  width: 5%;
}

.heart-wrap-display {
  position: absolute;
  bottom: 1rem;
  left: 2rem;
}

.page-traversal {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.traversal-button {
  font-size: 2rem;
  color: #394855;
}

.page-number {
  padding: 0 2rem;
  font-size: 1.75rem;
  color: #394855;
}

.blank {
  visibility: hidden;
}

.error-wrap {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}

.unauthenticated {
  color: #8f7682;
  font-size: 2.5rem;
}

@media (767px <= width) {
  .art-row {
    flex-direction: row;
  }

  .information-row {
    width: 100%;
  }

  .information-column {
    margin-top: 0;
    margin-left: 2rem;
    height: inherit;
  }

  .title-column {
    width: inherit;
  }

  .art-title {
    font-size: 1.25rem;
  }

  .artist-title {
    font-size: 1.25rem;
  }
}
