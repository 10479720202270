.unliked {
  color: #394855;
}

.liked {
  color: red;
}

.heart:active {
  transform: translateY(2px);
}
