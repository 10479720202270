.sg-art-display {
  display: flex;
  justify-content: center;
  height: 18rem;
}

.sg-img-wrap {
  display: flex;
  justify-content: center;
  height: 18rem;
}

.sg-art {
  max-width: 18rem;
}

.sg-display-wrap {
  padding-top: 2rem;
  max-width: 80%;
  margin: auto;
}

.sg-display-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sg-display-col {
  display: flex;
  justify-content: center;
  width: 100%;
}

.column {
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
}

.sg-information-display {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 4.5rem;
  position: relative;
}

.sg-heart-wrap {
  position: absolute;
  left: -2em;
  bottom: 0;
}

.sg-title {
  color: #394855;
  font-size: 1rem;
  margin: 0;
}

.sg-title > h1 {
  margin: 0.5rem 0;
}

.sg-artist-info {
  color: #9ba3a9;
  padding-bottom: 1rem;
}

.sg-artist-info h3 {
  margin: 0.25rem 0;
  font-size: 1.25rem;
}

.sg-art-info {
  color: #9ba3a9;
  font-size: 0.75rem;
}

.sg-art-info h3 {
  margin: 0.25rem 0;
}

.info-col {
  border-left: 2px solid #9ba3a9;
  justify-content: left;
  padding-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.art-subtext {
  color: #9ba3a9;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.75rem;
}

.art-subtext a {
  color: #9ba3a9;
  padding: 1rem;
}

.art-dimensions {
  padding-bottom: 0.5rem;
  max-width: 20rem;
  text-align: center;
}

@media (1200px <= width) {
  .sg-display-row {
    flex-direction: row;
  }

  .sg-display-col {
    width: 50%;
  }

  .info-col {
    margin-left: 5rem;
    padding-left: 4rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .sg-img-wrap {
    height: 30rem;
    width: 50rem;
  }

  .sg-art-display {
    height: 30rem;
    width: 50rem;
  }

  .sg-heart-wrap {
    top: 3rem;
    left: -2rem;
  }

  .sg-art {
    max-width: 40rem;
  }
}
